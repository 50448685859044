import React from "react";
import styles from "../../assets/styles/HomePage.module.css";
import clockImg from "../../assets/images/ClockImg.png";
import rating from "../../assets/images/Rating.png";
import rightArrow from "../../assets/images/RightArrow.png";
import { useNavigate } from "react-router-dom";

function Card({ imageSrc, courseTitle, courseDetail, timeDetail, cardID }) {
  const navigation = useNavigate();

  return (
    <div className={styles.card}>
      <img src={imageSrc} alt="Home Backgroud Image" className={styles.cardImg} />
      <div className={styles.textContainer}>
        <span className={styles.courseTitle}>{courseTitle}</span>
        <p className={styles.courseDetail}>{courseDetail}</p>
        <div className={styles.learnAndClockFlex}>
          <div className={styles.cardFooter}>
            <img src={clockImg} alt="Clock Icon" className={styles.clockIcon} />
            <p className={styles.timeDetail}>{timeDetail}</p>
            <div className={styles.footerVL}></div>
            <img src={rating} alt="Feedback Icon" />
          </div>
          <div className={styles.learnMoreDiv}>
            <button
              className={styles.learnMoreText}
              onClick={() => {
                navigation(`/coursedetail/${cardID}`, { state: { data: cardID } });
              }}>
              Learn More
            </button>
            <img src={rightArrow} alt="Feedback Icon" className={styles.rightArrowSet} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
