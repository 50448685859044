import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/styles/Inquiry.module.css";
import InquiryImg from "../../assets/images/InquiryImg.png";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router-dom";
import Request from "axios";
import useLoader from "../../hooks/useLoader";

function Section2() {
  const navigation = useNavigate();
  const fullname = useRef();
  const email = useRef();
  const phone = useRef();
  const [status, setStatus] = useState("selectCountry");
  const [coursesInquiry, setCoursesInquiry] = useState();
  const [changedCourse, setChangedCourse] = useState();
  const { isLoading: isInquiryLoading, setLoader: setInquiryLoader, Loader: InquiryLoader } = useLoader();
  const { setLoader: setCourseLoader, Loader: CourseLoader } = useLoader();

  useEffect(() => {
    setCourseLoader(true);
    Request.get(`${BASE_URL}courseGet`)
      .then((res) => {
        setCoursesInquiry(res?.data);
      })
      .catch((err) => {
        alert("Error fetching inquiry courses", err);
        console.log("Error fetching inquiry courses", err);
      })
      .finally(() => {
        setCourseLoader(false);
      });
  }, []);

  async function sendInquiry() {
    setInquiryLoader(true);
    const statusVal = document.getElementById("status")?.value;
    Request.post(`${BASE_URL}inquiry`, {
      fullname: fullname.current.value,
      email: email.current.value,
      phone: phone.current.value,
      interestedcourse: changedCourse ? changedCourse : "",
      education: document.getElementById("education").value,
      status: statusVal ? statusVal : null,
      country: document.getElementById("country").value,
      emailsubscribe: document.getElementById("subscribe").checked,
    })
      .then((res) => {
        if (res?.status === 200) {
          alert("Data Submitted Successfully.");
          // navigation("/");
        } else {
          alert("Error in data sending.");
        }
      })
      .catch((err) => {
        console.log("Error Inquiry: ", err);
      })
      .finally(() => {
        setInquiryLoader(false);
      });
  }

  return (
    <div className={styles.section2}>
      <CourseLoader />

      {isInquiryLoading ? (
        <InquiryLoader type="component" />
      ) : (
        <>
          <div className={styles.imgDiv}>
            <img src={InquiryImg} alt="Inquiry Image" className={styles.contentLogo} />
          </div>
          <div className={styles.formDiv}>
            <p className={styles.titleText}>Inquiry Form</p>
            <div className={styles.form}>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Full Name<p className={styles.formMandatoryLogo}>*</p>
                </label>
                <input type="text" className={styles.forminput} ref={fullname} placeholder="Full Name" />
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Email<p className={styles.formMandatoryLogo}>*</p>
                </label>
                <input type="text" className={styles.forminput} ref={email} placeholder="Email" />
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Phone <p className={styles.formMandatoryLogo}>*</p>
                </label>
                <input type="text" className={styles.forminput} ref={phone} placeholder="Phone No." />
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Level of Education <p className={styles.formMandatoryLogo}>*</p>
                </label>
                <select className={styles.formSelectinput} id="education">
                  <option value="please select" disabled selected hidden>
                    Please Select
                  </option>
                  <option value="Less than High School Graduate">Less than High School Graduate</option>
                  <option value="High School Graduate">High School Graduate</option>
                  <option value="Associate Degree">Associate Degree</option>
                  <option value="Bachelor’s Degree">Bachelor’s Degree</option>
                  <option value="Graduate Degree or Higher">Graduate Degree or Higher</option>
                </select>
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Course Interested <p className={styles.formMandatoryLogo}>*</p>
                </label>
                <select
                  className={styles.formSelectinput}
                  onChange={(s) => {
                    setChangedCourse(s.target.value);
                    console.log("===", s.target.value);
                  }}
                  id="interestedCourse">
                  <option value="selectCourse" disabled selected hidden>
                    Select Course
                  </option>
                  {coursesInquiry?.map((obj, i) => (
                    <option value={obj.id} className={styles.element}>
                      {obj.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Select Country <p className={styles.formMandatoryLogo}>*</p>
                </label>
                <select
                  className={styles.formSelectinput}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  id="country">
                  <option value="selectCountry" disabled selected hidden>
                    Select Country
                  </option>
                  <option value="CANADA">CANADA</option>
                  <option value="INDIA">INDIA</option>
                  <option value="USA">USA</option>
                </select>
              </div>

              {status == "INDIA" && (
                <div className={styles.inputData}>
                  <label for="" className={styles.formLabel}>
                    Select Status <p className={styles.formMandatoryLogo}>*</p>
                  </label>
                  <select className={styles.formSelectinput} id="status">
                    <option value="selectStatus" disabled selected hidden>
                      Select Status
                    </option>
                    <option value="Full-Time Student">Full-Time Student</option>
                    <option value="Part-Time Student">Part-Time Student</option>
                    <option value="Full-Time Employed">Full-Time Employed</option>
                    <option value="Part-Time Employed">Part-Time Employed</option>
                    <option value="Unemployed">Unemployed</option>
                  </select>
                </div>
              )}

              <div className={styles.inputData}>
                <div className={styles.subscribeDiv}>
                  <input type="checkbox" id="subscribe" name="subscribe" className={styles.checkbox} /> {" "}
                  <label for="subscribe" className={styles.text}>
                    I subscribe to send me update and information by email.
                  </label>
                </div>
              </div>
              <div className={styles.inputData}>
                <div className={styles.btnDiv}>
                  <button className={styles.submitBtn} onClick={() => sendInquiry()}>
                    <p className={styles.submitBtnText}>Submit</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Section2;
