import React from 'react';
import styles from '../assets/styles/ContactUs.module.css'
import Section2 from './ContactUs/Section2';

function ContactUs() {
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.section1}> 
                <p className={styles.titleText}>CONTACT US</p>   
            </div>
        <Section2 />
        </div>
    );
}

export default ContactUs;