import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../../Pages/HomePage";
import LockContentModal from "../../Pages/Coursecontent/LockContentModal";
import Course from "../../Pages/Course";
import ContactUs from "../../Pages/ContactUs";
import AboutUs from "../../Pages/AboutUs";
import Inquiry from "../../Pages/Inquiry";
import CourseDetail from "../../Pages/CourseDetail";
import ReviewPage from "../../Pages/Coursecontent/ReviewPage";
import LockContext from "./LockContext";
import AdminRoute from "./AdminRoute";
import PublicRoute from "./PublicRoute";
import Students from "../../Pages/AdminModule/Students";
import Courses from "../../Pages/AdminModule/Courses";
import Instructor from "../../Pages/AdminModule/Instructor";
import AddCourse from "../../Pages/AdminModule/AddCourse";
import { Inquiry as AdminInquiry } from "../../Pages/AdminModule/Inquiry";
import { useSelector } from "react-redux";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Navigation(props) {
  const [showModal, setShowModal] = useState();
  const [showLockModal, setShowLockModal] = useState(false);
  const role = useSelector((state) => state.allData.user_role);

  // const [ShowLogin, setShowLogin] = useState(false)
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Listing all admin routes  */}

          <Route element={<AdminRoute />}>
            <Route path="/admin/students" element={<Students />} />
            <Route path="/admin/courses" element={<Courses />} />
            <Route path="/admin/inquiry" element={<AdminInquiry />} />
            <Route path="/admin/instructor" element={<Instructor />} />
            <Route path="/admin/addCourse" element={<AddCourse />} />
            <Route
              path="/admin/editCourse/:id/"
              element={<AddCourse isEdit={true} />}
            />
          </Route>

          {/* Listing all public routes  */}
          <Route
            element={
              <PublicRoute
                setShowModal={setShowModal}
                showModal={showModal}
                showLockModal={showLockModal}
                setShowLockModal={setShowLockModal}
              />
            }
          >
            <Route
              path="/"
              Component={() => (
                <HomePage anim={props.anim} setShowModal={setShowModal} />
              )}
            />
            <Route path="/courses" Component={() => <Course />} />
            <Route
              path="/content"
              Component={() => <LockContentModal anim={props.anim} />}
            />
            {/* <Route path="/register" Component={() => <RegisterModal anim={props.anim} />} /> */}
            <Route path="/contactus" Component={() => <ContactUs />} />
            <Route path="/aboutus" Component={() => <AboutUs />} />
            <Route path="/inquiry" Component={() => <Inquiry />} />
            <Route
              path="/coursedetail/:id/"
              Component={() => (
                <LockContext.Provider
                  value={{ showLockModal, setShowLockModal }}
                >
                  <CourseDetail />
                </LockContext.Provider>
              )}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Navigation;
