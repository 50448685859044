// -------------------------------------------------------------------- All Imports Starts ------------------------------------------------------------------------------------------//
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminChild from "../Admin/AdminChild";
import AdminSidebar from "../Admin/AdminSidebar";
import styles from "../../assets/styles/Admin/AdminLayout.module.css";
// -------------------------------------------------------------------- All Imports Ends ------------------------------------------------------------------------------------------//

const AdminRoute = () => {
  // -------------------------------------------------------------------- States, Variable Initialization Starts ------------------------------------------------------------------//
  let role = useSelector((state) => state.allData.user_role);
  // -------------------------------------------------------------------- States, Variable Initialization Ends --------------------------------------------------------------------//

  // -------------------------------------------------------------------- In Return Block, Checking Valid Routes for Admin ------------------------------------------------------------------//
  return role == "Admin" ? (
    <div className={styles.adminLayout}>
      <AdminSidebar />
      <AdminChild>
        <Outlet />
      </AdminChild>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default AdminRoute;
