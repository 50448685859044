import styles from '../../assets/styles/Course.module.css'
import clockImg from '../../assets/images/ClockImg.png' 
import rating from '../../assets/images/Rating.png' 
import rightArrowOrange from '../../assets/images/rightArrowOrange.png' 
import {  useNavigate } from "react-router-dom";


function Listview({imageSrc, courseTitle,courseDetail, timeDetail}) {
    const navigation = useNavigate();

  return (
   <>
        <div className={styles.mainDivFlex}>

            <div className={styles.subOneDiv}>
                <img src={imageSrc}  className={styles.cardImgList} />
            </div>

            <div className={styles.subTwoDiv}>
                <span className={styles.courseTitle}>
                    {courseTitle}
                </span>
                <p className={styles.courseDetail}>
                    {courseDetail}
                </p>
                <div className={styles.cardFooter}>
                    <img src={clockImg} alt="Clock Icon" className={styles.clockIcon} />
                    <p className={styles.timeDetail}>
                    {timeDetail}
                    </p>
                    <div className={styles.footerVL}></div>
                    <img src={rating} alt="Feedback Icon"  />
                </div>  
                <div className={styles.lerarnMoreFlex}>
                    <p className={styles.learnMoreText} onClick={() => {
                navigation("/coursedetail");
              }}>
                        Learn More
                    </p>
                    <img src={rightArrowOrange} alt="Feedback Icon" className={styles.rightArrowSet} />
                </div>
            </div>

        </div>
        <div className={styles.HLine}> </div>
   </>
  )
}

export default Listview