import { useEffect, useState } from "react";
import "./App.css";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/Footer";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setFirstLoad(() => true);
    document.title = "Param Academy";
  }, []);
  return (
    <>
    <div style={{ maxWidth: width, maxHeight: height }}></div>
      <div className="navRoutes">
        <Navigation anim={!firstLoad} />
      </div>
    </>
  );
}

export default App;
