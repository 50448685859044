import React, { useRef } from "react";
import styles from "../../assets/styles/ContactUs.module.css";
import phoneIcon from "../../assets/images/ContactUsPhoneLogo.png";
import mailIcon from "../../assets/images/ContactUsEmailLogo.png";
import locationIcon from "../../assets/images/ContactUsLocationLogo.png";
import FacebookLogo from "../../assets/images/ContactUsFB.png";
import InstaLogo from "../../assets/images/ContactUsInsta.png";
import LinkedinLogo from "../../assets/images/ContactUsLinkedin.png";
import { BASE_URL } from "../../components/baseURL";
import { useNavigate } from "react-router-dom";
import Request from "axios";
import useLoader from "../../hooks/useLoader";

function Section2() {
  const { isLoading, setLoader, Loader } = useLoader();
  const navigation = useNavigate();
  const firstname = useRef();
  const lastname = useRef();
  const email = useRef();
  const phone = useRef();
  const subject = useRef();
  const message = useRef();
  async function createUser() {
    setLoader(true);
    Request.post(`${BASE_URL}contactus`, {
      firstname: firstname.current.value,
      lastname: lastname.current.value,
      email: email.current.value,
      phone: phone.current.value,
      subject: subject.current.value,
      message: message.current.value,
    })
      .then((res) => {
        console.log(res, "<===res");
        if (res?.status === 200) {
          alert("Data Submitted Successfully.");
          navigation("/contactus");
        } else {
          alert("Error in data sending.");
        }
      })
      .catch((err) => {
        console.log("Error contact us user: ", err);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  return (
    <div className={styles.section2}>
      <div className={styles.infoDiv}>
        <div className={styles.infoElement}>
          <p className={styles.titleText}>USA</p>
          <div className={styles.contentDiv}>
            <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>(+1) 732 404 0466</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>info@paramacademy.com</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={locationIcon} alt="Location Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>Middlesex Essex Turnpike #104, Iselin, NJ 08830, United States</span>
          </div>
        </div>
        <div className={styles.infoElement}>
          <p className={styles.titleText}>INDIA</p>
          <div className={styles.contentDiv}>
            <img src={phoneIcon} alt="Phone Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>(+91) 701 669 3717</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={mailIcon} alt="Mail Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>parameduindia@gmail.com</span>
          </div>
          <div className={styles.contentDiv}>
            <img src={locationIcon} alt="Location Icon" className={styles.contentLogo} />
            <span className={styles.contentText}>
              709 710 Everest Onyx, Opp Inox Multiplex, Race Course Rd, Vadodara, Gujarat 390007
            </span>
          </div>
        </div>
        <div className={styles.socialIconsDiv}>
          <div>
            <a href="" className={styles.linkButton}>
              <img src={FacebookLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
          <div>
            <a href="" className={styles.linkButton}>
              <img src={LinkedinLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
          <div>
            <a href="" className={styles.linkButton}>
              <img src={InstaLogo} alt="" className={styles.socialIcons} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.formDiv}>
        {isLoading ? (
          <Loader type="component" />
        ) : (
          <div className={styles.form}>
            <div className={styles.formRow}>
              <div className={styles.inputData}>
                <label for="Fname" className={styles.formLabel}>
                  {" "}
                  First Name
                  <input type="text" className={styles.forminput} ref={firstname} required />
                </label>
              </div>
              <div className={styles.inputData}>
                <label for="Lname" className={styles.formLabel}>
                  Last Name
                  <input type="text" className={styles.forminput} ref={lastname} required />
                </label>
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  {" "}
                  Email
                  <input type="text" className={styles.forminput} ref={email} required />
                </label>
              </div>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  Phone Number
                  <input type="text" className={styles.forminput} ref={phone} required />
                </label>
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  {" "}
                  Subject
                  <input type="text" className={styles.forminput} ref={subject} required />
                </label>
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.inputData}>
                <label for="" className={styles.formLabel}>
                  {" "}
                  Message
                  <input type="text" className={styles.forminput} ref={message} required />
                </label>
              </div>
            </div>
            <div className={styles.btnDiv}>
              <button className={styles.contactUsBtn} onClick={() => createUser()}>
                <p className={styles.contactusBtnText}>Send Message</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Section2;
